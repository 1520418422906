import React, { Props } from "react"
import { Collapse, Input, Form, Icon, Button, Spin, InputNumber, Modal, Row, Col, Tooltip  } from 'antd'
import { Helmet } from "react-helmet"
import SiteLayout from "../components/SiteLayout";
import axios from 'axios';
import Img from "gatsby-image";
import { StaticQuery, graphql } from 'gatsby';
import AppStoreButton from "../components/Buttons/AppStoreButton";
import GooglePlayButton from "../components/Buttons/GooglePlayButton";
import { loadStripe } from '@stripe/stripe-js';

const Panel = Collapse.Panel;


enum Page {
    Loading = 0,
    Purchase = 1,
    ThankYou = 2,
    Error = 3,
    ErrorUnkown = 4,
}

// @ts-ignore
import styles from './bestill.module.scss';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
const API_URL = process.env.GATSBY_APP_API_URL;
const API_TOKEN = process.env.GATSBY_APP_API_TOKEN;
const STRIPE_API_KEY = process.env.GATSBY_STRIPE_API_KEY;

const stripePromise = loadStripe(STRIPE_API_KEY);

class BekreftForm extends React.Component<any, any> {

    constructor(props) {
        super(props);
        this.state = {
            page: Page.Loading,
            buttonsDisabled: true,
            startSessionRequest: null,
            errorResponse: null,
            inputDisabled: false,
            buttonLoading: false,

        };

        this.onChangeInput = this.onChangeInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.lockForm = this.lockForm.bind(this);
        this.handleEmptyValues = this.handleEmptyValues.bind(this);


    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const status = urlParams.get('status');
        if (status == "1") {
            this.setState({ page: Page.ThankYou });
        }
        else {
            this.setState({ page: Page.Purchase });
        }
    }

    

    async onChangeInput(e: React.ChangeEvent<HTMLInputElement>) {
        let fieldName = e.target.name;
        let value = e.target.value;
        if (fieldName == "email") {
            await this.setState({ emailValue: value });
        }
        if (fieldName == "confirmEmail") {
            await this.setState({ confirmEmailValue: value });
        }

        const nextState = {
            ...this.state.startSessionRequest,
            [fieldName]: value,
        };
        this.setState({ startSessionRequest: nextState });
        this.handleEmptyValues(value);



        
    }

    handleEmptyValues(value: string) {
        // This is hacky but I don't want to spend more time on it and it seems to work...
        setTimeout(function () {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    
                    console.log(values);
                    if (values.newConsent && (values.epost === values.bekreftEpost)) {
                        this.setState({ buttonsDisabled: false });
                    }
                    else {
                        this.setState({ buttonsDisabled: true });
                    }
                } else {
                    this.setState({ buttonsDisabled: true });
                }
            });
            /* if (this.state.startSessionRequest !== null) {
                if (this.state.startSessionRequest.email !== this.state.startSessionRequest.confirmEmail) {
                    console.log("Emails do not match");
                    this.setState({ buttonsDisabled: true });
                }
                else {
                    this.setState({ buttonsDisabled: false });
                }
            } */
        }.bind(this), 200);
    }

    lockForm(locked: boolean) {
        this.setState({ buttonsDisabled: locked, inputDisabled: locked });
    }

    handleSubmit = async (e) => {

        e.preventDefault();
        this.setState({ buttonLoading: true });


         var config = {
            headers: {'Authorization': "bearer " + API_TOKEN}
        };
        var body = { 
            email: this.state.startSessionRequest.email,
            firstName: this.state.startSessionRequest.firstName,
            lastName: this.state.startSessionRequest.lastName,
            phoneNumber: this.state.startSessionRequest.phoneNumber,
            streetAddress: this.state.startSessionRequest.streetAddress,
            postCode: this.state.startSessionRequest.postCode,
            postalArea: this.state.startSessionRequest.postalArea,
            meterNumber: this.state.startSessionRequest.meterNumber,
            meteringPointIdLastFour: this.state.startSessionRequest.mpid,
        };

        console.log(body);

        axios.post(API_URL + "/api/purchase/startsession", body, config).then(async res => {
            if (res.status >= 200 && res.status < 300) {
                //this.setState({ page: Page.ThankYou });
            
                const sessionId = res.data;
                const stripe = await stripePromise;
                const { error } = await stripe.redirectToCheckout({ sessionId });
                this.setState({ buttonLoading: false });
            }
            else {
                this.setState({ page: Page.ErrorUnkown });
            }
        }).catch(err => {
            this.setState({ page: Page.Error,  errorResponse: err.response.data, buttonLoading: false});
        });
    }

    renderPurchasePage() {
        const { getFieldDecorator } = this.props.form;

        return (
            <StaticQuery
                query={graphql`
                {
                    pitch: file(relativePath: {eq: "homescreen.png"}) {
                        childImageSharp{
                        fluid(maxWidth: 680) {
                            ...GatsbyImageSharpFluid_noBase64
                        }
                    }
                    }
                }
            `
                }
                render={data => (
                    <div style={{textAlign: 'center'}} >
                        {/* <img width="100" src="https://if-vid-brand-cdn.azureedge.net/images/logo/logo.svg"/><br/><br/><br/> */}



                        <h2 style={{ textAlign: 'center' }}>Bestill din Oss-Brikke</h2>
                        <div className={styles.introsubtext}>
                            Vi er for øyeblikket utsolgt for Oss-brikker og vi vil komme med mer informasjon når vi har fått mer på lager.
                        </div>




                        { /*

                        <h2  style={{textAlign: 'center'}}>Bestill din Oss-Brikke</h2> 
                        <div className={styles.introsubtext}>
                            Pris 39kr/mnd og 990kr for Oss-brikken
                            <p style={{ fontSize:'80%' }}>Vi starter å trekke månedsabonnementet ditt 30 dager etter bestilling.</p>
                        </div>
                      
                        <div className={styles.introsubtext}>
                            <i>
                            Vennligst fyll inn feltene nedenfor for å gjennomføre kjøpet og få tilgang til tjenestene. 
                            Informasjonen blir brukt til å opprette en bruker til deg i Oss-appen, og sikrer at det blir en match mellom Oss-brikken og strømmåleren din.
                            </i>
                        </div> 
                        <div className={styles.introsubtext}>
                        <p style={{ fontSize:'80%' }}>Bestilling gjelder kun privatpersoner. Bedrifter kan kontakte oss på hei@oss.no for å motta mer informasjon og tilbud på våre tjenester. </p>
                        </div>
                        <div className={styles.introtext}>
                            <Form hideRequiredMark={true} onSubmit={this.handleSubmit} className={styles.confirmform} layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                                <Form.Item className={styles.compactfield} label="Fornavn">
                                    {getFieldDecorator('firstname', {
                                        rules: [{ required: true, message: 'Du må fylle inn fornavn', max: 30 }],
                                        //initialValue: this.state.confirmationRequest.firstName
                                    })(
                                        <Input autoComplete="off" disabled={this.state.inputDisabled} name="firstName" size="large" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Fornavn" onChange={this.onChangeInput} />
                                    )}
                                </Form.Item>

                                <Form.Item
                                    label="Etternavn"
                                >
                                    {getFieldDecorator('lastname', {
                                        rules: [{ required: true, message: 'Du må fylle inn etternavn', max: 30 }],
                                        //initialValue: this.state.confirmationRequest.lastName
                                    })(
                                        <Input disabled={this.state.inputDisabled} name="lastName" size="large" prefix={<Icon type="" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Etternavn" onChange={this.onChangeInput} />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Epost"
                                >
                                    {getFieldDecorator('epost', {
                                        rules: [{ required: true, message: 'Du må fylle inn epost', max: 50 }],
                                        //initialValue: this.state.confirmationRequest.epost
                                    })(
                                        <Input  disabled={this.state.inputDisabled} name="email" size="large" prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)'}} />} placeholder="Epost" onChange={this.onChangeInput} />
                                        
                                    )}
                                   
                                  
                                </Form.Item>
                               
                                <Form.Item
                                    label="Bekreft Epost"
                                >
                                    {getFieldDecorator('bekreftEpost', {
                                        rules: [{ required: true, message: 'Du må fylle inn epost igjen' }],
                                        //initialValue: this.state.confirmationRequest.email
                                    })(
                                        <Input disabled={this.state.inputDisabled} name="confirmEmail" size="large" prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Bekreft Epost" onChange={this.onChangeInput} />
                                    )}
                                     <Tooltip title="E-postadressen bruker du for å logge inn i appen."><Button type="link" style={{ margin: '0 8px' }}>Hva trenger dere epost til?</Button></Tooltip>
                                </Form.Item>
                                <Form.Item
                                    label="Telefonnummer"
                                >
                                    {getFieldDecorator('phoneNumber', {
                                        rules: [{ required: true, message: 'Du må fylle inn telefonnummer', min: 8, max: 8 }],
                                        //initialValue: this.state.confirmationRequest.phoneNumber
                                    })(
                                        <Input type="number" disabled={this.state.inputDisabled} name="phoneNumber" size="large" prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Telefonnummer" onChange={this.onChangeInput} />
                                    )}
                                </Form.Item>

                                <br></br>
                                <br></br>
                                <div className={styles.introsubtext}>
                                    Adresse der måleren står:
                                </div>


                                <Form.Item className={styles.compactfield}
                                    label="Adresse"
                                >
                                    {getFieldDecorator('streetAddress', {
                                        rules: [{ required: true, message: 'Du må fylle inn adresse', max: 50 }],
                                        //initialValue: this.state.confirmationRequest.streetAddress
                                    })(
                                        <Input disabled={this.state.inputDisabled} name="streetAddress" size="large" prefix={<Icon type="home" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Gateadresse" onChange={this.onChangeInput} />
                                    )}
                                </Form.Item>

                                <Form.Item className={styles.compactfield}
                                    label="Postnummer"
                                >
                                    {getFieldDecorator('postCode', {
                                        rules: [{ required: true, message: 'Du må fylle inn gyldig postnummer', min: 4, max: 4 }],
                                        //initialValue: this.state.confirmationRequest.postCode
                                    })(
                                        <Input type="number" disabled={this.state.inputDisabled} name="postCode" size="large" prefix={<Icon type="" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Postnummer" onChange={this.onChangeInput} />
                                    )}
                                </Form.Item>

                                <Form.Item
                                    required
                                    help=""
                                    label="Sted"
                                >
                                    {getFieldDecorator('sted', {
                                        rules: [{ required: true, message: 'Du må fylle inn sted', max: 30 }],
                                        //initialValue: this.state.confirmationRequest.postalArea
                                    })(
                                        <Input disabled={this.state.inputDisabled} name="postalArea" size="large" prefix={<Icon type="" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Stedsnavn" onChange={this.onChangeInput}  />
                                    )}
                                </Form.Item>
                                <Form.Item
                                    label="Målernummer"
                                >
                                    {getFieldDecorator('malerNummer', {
                                        rules: [{ required: true, message: 'Du må fylle inn målernummer', max: 30 }],
                                        //initialValue: this.state.confirmationRequest.meterNumber
                                    })(
                                        <Input type="number" disabled={this.state.inputDisabled} name="meterNumber" size="large" prefix={<Icon type="cluster" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Målernummer" onChange={this.onChangeInput} />
                                    )}
                                    <Tooltip title="Målernummeret er på 16 siffer, og står på måleren din. Du finner det også på faktura fra din strømleverandør, eller inne på min side hos din strøm – og nettleverandør."><Button type="link" style={{ margin: '0 8px' }}>Hvor finner jeg målernummer?</Button></Tooltip>
                                </Form.Item>
                                <Form.Item className={styles.compactfield}
                                    label="MålepunktID:"
                                >
                                    {getFieldDecorator('mpid', {
                                        rules: [{ required: true, message: 'Du må fylle inn de siste fire sifrene i målepunkt-ID', min: 4, max: 4 }],
                                        //initialValue: this.state.confirmationRequest.postCode
                                    })(
                                        <Input type="number" disabled={this.state.inputDisabled} name="mpid" size="large" prefix={<Icon type="" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Målepunktid" onChange={this.onChangeInput} />
                                    )}
                                    <h4>Kun de 4 siste sifrene</h4>
                                     <Tooltip title="Du finner målepunktID på faktura fra din strømleverandør, eller inne på min side hos din strøm – og nettleverandør. MålepunktID er et unikt identifikasjonsnummer på ditt elektriske anlegg og står ikke på måleren din. Vi trenger kun de 4 siste sifrene."><Button type="link" style={{ margin: '0 8px' }}>Hvor finner jeg MålepunktID?</Button></Tooltip>
                                </Form.Item>
                                

                                
                                <br />
                                <div className={styles.introsubtext}> Jeg samtykker til elektronisk kommunikasjon, samt godkjenner Oss Norges <a target="_blank" href="/kjopsvilkar"  className={styles.reservationLink}>kjøpsvilkår</a> og <a target="_blank" href="/brukervilkar"  className={styles.reservationLink}>brukervilkår</a>. </div>
                            
                                <Form.Item
                                    label="Samtykke"
                                >
                                    {getFieldDecorator('newConsent', {
                                        rules: [{ required: true, message: 'Du må samtykke for å bestille brikke!'}],
                                        
                                    })(
                                        <Input disabled={this.state.fieldsActive} type="checkbox" name="newConsent" size="large" prefix={<Icon type="" style={{ color: 'rgba(0,0,0,.25)' }} />}  onChange={this.onChangeInput} />
                                    )}

                                </Form.Item>

                                <Form.Item label=" " colon={false}>
                                    <Button loading={this.state.buttonLoading} type="primary" htmlType="submit" className={styles.confirmbutton} size="large" disabled={this.state.buttonsDisabled} >
                                        Gå til betaling
                                    </Button>
                                </Form.Item>
                                
                            </Form>
                        </div>

                        */}



                        { /*

                        <Row className={styles.container}>
                            <Col xs={24} sm={24} lg={24}>
                                <Row type="flex" align="top" gutter={24}>
                                            <h1>Med Oss får du...</h1>
                                </Row>
                                <Row>
                                    <Col xs={24} sm={24} lg={24}>
                                        <div className={styles.subheader_under}>
                                            Se strømforbruket ditt akkurat nå
                                        </div>
                                        <div style={{ textAlign: 'left' }} className={styles.text}>
                                            Hvor mye strøm bruker du akkurat nå? For folk flest er det helt gresk. Dette gir Oss deg muligheten til å se nå.  Kanskje klarer du ved hjelp av appen å finne strømtyvene i hjemmet ditt?
                                            <br/><br/>
                                        </div>
                                        
                                        <div className={styles.subheader_under}>
                                            Se ditt historiske forbruk
                                        </div>
                                        <div style={{ textAlign: 'left' }} className={styles.text}>
                                            Spol tilbake i tid og se ditt historiske strømforbruk. Denne tjenesten gir deg muligheten til å sammenligne forbruket ditt på timer, dager, uker, måneder og år.
                                            <br/><br/>
                                        </div>
                                        <div className={styles.subheader_under}>
                                            Sammenlign forbruket ditt med tilsvarende hjem
                                        </div>
                                        <div style={{ textAlign: 'left' }} className={styles.text}>
                                            Bruker jeg mye eller lite strøm lurer mange på. Få et forhold til hvordan strømforbruket ditt er sammenlignet med tilsvarende hjem. Er du en bedre strømforbruker enn naboen?
                                            <br/><br/>
                                        </div>
                                        <div className={styles.subheader_under}>
                                            Prediksjon av forbruk
                                        </div>
                                        <div style={{ textAlign: 'left' }} className={styles.text}>
                                            Med våre smarte algoritmer kan vi fortelle deg hvor mye strøm du mest sannsynlig kommer til å bruke i morgen.
                                            <br/><br/>
                                        </div>
                                       
                                        <div className={styles.subheader_under}>
                                        Se hva strømprisen er
                                        </div>
                                        <div style={{ textAlign: 'left' }} className={styles.text}>
                                        Du kan til enhver tid følge med på hva strømmen koster, og få muligheten til å tilpasse forbruket ditt til timer med lavere strømpris. Prisindikatoren i appen viser deg når det er billig og dyrt å bruke strøm
                                        <br/><br/>
                                        </div>
                                    </Col>
                                    
                                </Row>
                            </ Col>
                        </Row>
                        */}

                    </div>
                )}
            />
        )
    }

    renderLoadingPage() {
        return (
            <div>
                <h1>Laster inn...</h1>
                <div className={styles.introtext}><br /><br /><Spin indicator={antIcon} /></div>
            </div>
        )
    }

    renderThankYouPage() {
        window.scrollTo(0, 0);
        return (
            <div>
                <h1>Tusen takk! </h1>
                <div className={styles.introtext}>
                Gratulerer, du har nå bestilt deg en Oss-brikke. Utrolig kult at du ønsker å bli en del av oss. 
                Du mottar straks en bekreftelse på mail. Forventet levering er 5-7 virkedager.

                Mens du venter på Oss-brikken er det et par praktiske ting du kan gjøre 😉<br/><br/>
                </div>
                <div className={styles.subheader}>
                    Åpne HAN-porten på strømmåleren din
                </div>
                <div className={styles.text}>
                    Det første du må gjøre er å kontakte din nettleverandør (ikke strømleverandør), og be de om å åpne HAN-porten din. Hos de aller fleste nettleverandører kan dette gjøres via Min Side. Er ikke dette tilfellet hos deg, kontakt din nettleverandør via e-post eller telefon. 
                    <br/><br/>
                </div>
                <div className={styles.subheader}>
                Last ned og logg inn i Oss-appen
                </div>
                <div className={styles.text}>
                    Neste steg er å laste ned Oss-appen, som du finner her:
                    <Row style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center", maxWidth: 330 }} type="flex" justify="space-around" align="middle">
                        <Col xs={24} sm={12} style={{ marginTop: 24 }}><AppStoreButton /></Col>
                        <Col xs={24} sm={12} style={{ marginTop: 24 }}><GooglePlayButton /></Col>
                    </Row><br/><br/>
                </div>
                <div className={styles.text}>
                    Du logger inn i appen ved å legge inn e-post adressen du brukte ved bestilling av Oss-brikken, og vi sender deg en påloggingskode på mail (vær obs på at denne kan komme i søppelpost). Så fort Oss-brikken er på plass hos deg vil du få tilgang til alle funksjoner i appen.                </div>
            </div>
        )
    }

    renderErrorPage() {
        return (
            <div>
                <h1>Noe gikk galt</h1>
                <div className={styles.introtext}> {this.state.errorResponse.errorMessage}. Prøv på nytt, og ta kontakt med Oss på <a href="mailto:hei@oss.no">hei@oss.no</a> om problemet vedvarer.<br /><br /></div>
            </div>
        )
    }

    renderUnknownErrorPage() {
        return (
            <div>
                <h1>Ukjent Feil.</h1>
                <div className={styles.introtext}>Noe gikk galt.</div>
            </div>
        )
    }

    renderPage() {
        if (this.state.page == Page.Loading) {
            return this.renderLoadingPage();
        }
        if (this.state.page == Page.Purchase) {
            return this.renderPurchasePage();
        }
        if (this.state.page == Page.ThankYou) {
            return this.renderThankYouPage();
        }
        if (this.state.page == Page.Error) {
            return this.renderErrorPage();
        }
        if (this.state.page == Page.ErrorUnkown) {
            return this.renderUnknownErrorPage();
        }
    }

    render() {
        return (
            <SiteLayout>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Oss | Bestill</title>
                    <script>{`
                    {
            window.intercomSettings = {
                app_id: "wpsqtjpa"
            };
            }`}
                    </script>
                    <script>{`
                    {
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/wpsqtjpa';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()
            }`}
                    </script>
                </Helmet>
                <div className={styles.smallcontainer}>
                    {this.renderPage()}
                </div>
            </SiteLayout >
        );
    }
}

export default class Bekreft extends React.Component<any, any> {
    render() {
        const BekreftFormComponent = Form.create()(BekreftForm);
        return (<BekreftFormComponent />);
    }
}